import withDarkMode from 'next-dark-mode'
import type { AppProps } from 'next/app'
import NextNprogress from 'nextjs-progressbar'
import '../styles/globals.css'
import { ThemeProvider } from 'next-themes'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <NextNprogress
        color="#121212"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={false}
        options={{ easing: 'ease', speed: 500 }}
      />
      <ThemeProvider>
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}

export default withDarkMode(MyApp)
